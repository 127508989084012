import { Fragment } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import "./index.css";
import { Col, Row } from "reactstrap";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <Row className="main-layout ">
        <Col className="main bg-white">
          <Col className="sidebar">
            <SideBar />
          </Col>
          <Col className="main-content  mx-4 my-2 ">{children}</Col>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Layout;
