import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { BsSearch } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { TbCalendarDue } from "react-icons/tb";
import { api } from "../../service/api";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import { FaChevronDown } from "react-icons/fa";

const Sent = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [policy_no, setPolicyNo] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchPage, setFetchPage] = useState(true);

  useEffect(() => {
    setFetchPage(true);
    loadSent();
  }, [currentPage]);

  const search = () => {
    loadSent({ policy_no });
  };
  const loadSent = async () => {
    let page = fetchPage ? Number(currentPage) + 1 : 1;
    try {
      const response = await api.get("/sent", {
        params: {
          paginate: true,
          page,
          policy_no,
        },
      });
      setData(response.data.data);
      setTotalRows(response.data.total);
    } catch (error) {
    } finally {
      setLoading(false);
      setFetchPage(false);
    }
  };

  const columns = [
    {
      name: "Numero da apolice",
      maxWidth: "240px",

      selector: (row) => <span>{row.policy_no}</span>,
    },
    {
      name: "Email",
      maxWidth: "200px",
      selector: (row) => <span>{row.email}</span>,
    },
    {
      name: "Detalhes do contacto",
      allowOverflow: true,
      selector: (row) => (
        <div>
          <span>{row.cell_no}</span>
        </div>
      ),
    },
    {
      name: "Tipo de Notificao",

      allowOverflow: true,
      selector: (row) => {
        return <span>{row.notification_type}</span>;
      },
    },
    {
      name: "Data",

      selector: (row) => <span>{row.date}</span>,
    },
    {
      name: "Estado",
      allowOverflow: true,

      cell: (row) => {
        return (
          <div className="d-flex flex-row justify-content-end align-items-center ">
            {row.status}
          </div>
        );
      },
    },

    // {
    //   name: "Ações",
    //   allowOverflow: true,

    //   cell: (row) => {
    //     return (
    //       <div>
    //         <button
    //           className="btn btn-white btn-sm border-0 rounded-0"
    //           onClick={toggle}
    //         >
    //           <AiOutlineEye size={22} />
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];

  //THIS STYLES THE DATA TABLE
  const customStyles = {
    rows: {
      style: {
        fontWeight: "500",
        border: "1px solid #D4D9DD",
        fontSize: "12px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
        borderTop: "1px solid #D4D9DD",
        minHeight: "0px !important",
        color: "#000",
        backgroundColor: "#f2f2f2",
        fontWeight: "500",
      },
    },
  };

  const handlePagination = (page) => {
    setFetchPage(true);
    setCurrentPage(page.selected);
  };

  const CustomPagination = () => {
    const count = Number((totalRows / 10).toFixed(0));

    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel="..."
        pageCount={count || 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        activeClassName="active"
        forcePage={currentPage}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName={
          "pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
        }
      />
    );
  };

  return (
    <Fragment>
      <h4 className="mb-4">Notificações </h4>
      <div className="mb-3 d-flex gap-3">
        <div>
          <label htmlFor="search" className="form-label">
            Search
          </label>
          <div className="input-group">
            <span className="input-group-text bg-white" id="search">
              <BsSearch />
            </span>
            <input
              onChange={(e) => {
                setPolicyNo(e.target.value);
              }}
              type="text"
              className="form-control form-control-sm"
              placeholder=""
            />
          </div>
        </div>
        {/* <div>
          <label htmlFor="start_date" className="form-label">
            Policy
          </label>
          <select
            className="form-select form-select-sm"
            aria-label="Default select example"
          >
            <option selected>All</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div>
          <label htmlFor="end_date" className="form-label">
            Policy Status
          </label>
          <select
            className="form-select form-select-sm"
            aria-label="Default select example"
          >
            <option selected>All</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div> */}
        <div>
          <label htmlFor="end_date" className="form-label text-white">
            .
          </label>
          <div className="input-group">
            <Button
              onClick={(e) => {
                search();
              }}
              color="danger"
              outline
              size="sm"
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <DataTable
        noHeader
        pagination
        columns={columns}
        customStyles={customStyles}
        className="react-dataTable"
        sortIcon={<FaChevronDown size={6} />}
        data={data}
        progressPending={loading}
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        paginationComponent={CustomPagination}
      />

      <Modal isOpen={modal} toggle={toggle}>
        <div className="text-center p-3 border-bottom">Member Details</div>
        <ModalBody>
          <p className="text-muted">Details of last email sent to member.</p>
          <Row>
            <div className="col-12 col-md-6">
              <p className="text-muted mb-0">
                <small>Last Sent</small>
              </p>
              <p>14/09/2023 6:58 PM</p>
            </div>
            <div className="col-12 col-md-6">
              <p className="text-muted mb-0">
                <small>Category</small>
              </p>
              <p>Renewals</p>
            </div>
          </Row>
          <Row>
            <Col>
              <p className="text-muted mb-0">
                <small>Name</small>
              </p>
              <p>Mikael Rodriguez Santos</p>
            </Col>{" "}
            <Col>
              <p className="text-muted mb-0">
                <small>Tipo de Notificaçao</small>
              </p>
              <p>Mikael Rodriguez Santos</p>
            </Col>
          </Row>
          <div>
            <p className="text-muted mb-0">
              <small>Status</small>
            </p>
            <p>Successful</p>
          </div>
        </ModalBody>
        <div className="text-center p-3">
          <Button color="primary" size="sm" onClick={toggle}>
            Close
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Sent;
