import React, { useEffect, useState } from "react";
import {
  Button,
  CardText,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../service/api";
import { SweetAlert } from "../../components/Alerts";
import { useAuth } from "../../context/AuthContext";
import {
  AiOutlinePoweroff,
  AiOutlineEyeInvisible,
  AiFillBell,
  AiOutlineEye,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";

function ModalEdit({ client_id }) {
  const [modal, setModal] = useState(false);

  const { user } = useAuth();

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const [loading, setIsLoading] = useState(false);

  const [clientInfo, setClientInfo] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientDob, setClientDob] = useState("");

  useEffect(() => {
    loadClient();
  }, [modal]);

  const loadClient = async () => {
    const response = await api.get(`/clients/${client_id}`);
    setClientInfo(response.data);
    setClientEmail(response.data.CLI_email);
    setClientName(response.data.CLI_InsuredFullName);
    setClientPhone(response.data.CLI_CellNumber);
    setClientDob(response.data.SMS_dob);
  };

  const handleSubmit = () => {
    try {
      const response = api.put(`clients/${client_id}`, {
        clientEmail,
        clientPhone,
        clientDob,
      });

      SweetAlert({
        title: "Actualizado Com Sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      SweetAlert({
        title: "Ocorreu um erro interno ao tentar efectuar a operação",
        text: "Se o problema persistir contacte o suporte técnico.",
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        className="btn btn-white btn-sm border-0 rounded-0"
        onClick={toggle}
      >
        <AiOutlineEye size={22} />
      </button>

      <Modal isOpen={modal} toggle={toggle} style={{ width: "440px" }}>
        <ModalHeader>
          <h4 className="fw-700" style={{ fontSize: "18px" }}>
            Informacao do Cliente
          </h4>
        </ModalHeader>

        <ModalBody>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="memberprovider">Nome </Label>
                <h6>{clientName}</h6>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Numero de Apolices </Label>
                <h6>{clientInfo?.policies?.length}</h6>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <FormGroup>
              <Label for="memberprovider"> Apolices </Label>
              <table>
                {clientInfo?.policies?.map((item) => {
                  return (
                    <tr>
                      {item.P_OtherPolicyNo}{" "}
                      <span
                        style={{
                          color: item.P_Status === "A" ? "green" : "#F00",
                        }}
                      >
                        {item.P_Status == "A" ? "Activo" : "Cancelado"}
                      </span>
                    </tr>
                  );
                })}
              </table>
            </FormGroup>
          </Row>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="memberprovider">Email do Cliente </Label>
                <Input
                  value={clientEmail}
                  bsSize="sm"
                  type="email"
                  onChange={(e) => {
                    setClientEmail(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Numero de Telephone </Label>
                <Input
                  value={clientPhone}
                  onChange={(e) => {
                    setClientPhone(e.target.value);
                  }}
                  type="number"
                  bsSize="sm"
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for="memberprovider">
                  Data de Nascimento (mes-dia){" "}
                </Label>
                <Input
                  value={clientDob}
                  onChange={(e) => {
                    setClientDob(e.target.value);
                  }}
                  bsSize="sm"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#fff",
              color: "#747587",
              border: "none",
            }}
          >
            <span className="ms-1 me-1">Close </span>
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
              toggle();
            }}
            color="primary"
            className="btn-sm rounded"
            bssize="sm"
            style={{ width: "134px", marginLeft: "20px" }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalEdit;
