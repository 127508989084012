import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import {
  AiFillEdit,
  AiFillFilter,
  AiOutlineClose,
  AiOutlineExport,
} from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Progress,
  Label,
  Row,
  Table,
} from "reactstrap";
import { formatCurrency } from "../../utility/FormatCurrency";
import { api, baseURL } from "../../service/api";

import { getUser } from "../../service/auth";

const Reports = () => {
  const [inventoryRadio, setInventoryRadio] = useState(1);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [total, setTotal] = useState([]);
  const [policy_no, setPolicyNo] = useState("");
  const [broker, setBroker] = useState("");
  const [product_id, setProductId] = useState("");
  const [created_date, setCreatedDate1] = useState("");
  const [created_date2, setCreatedDate2] = useState("");

  const [perPage, setPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(0);

  const user = getUser();

  const currentDate = new Date();

  // Calculate first day of the current month
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  // Calculate last day of the current month
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Format first day in YYYY-MM-DD format
  const firstDayFormatted = `${firstDayOfMonth.getFullYear()}-${(
    firstDayOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${firstDayOfMonth
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  // Format last day in YYYY-MM-DD format
  const lastDayFormatted = `${lastDayOfMonth.getFullYear()}-${(
    lastDayOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;

  const [firstDay, setFirstDay] = useState(firstDayFormatted);
  const [lastDay, setLastDay] = useState(lastDayFormatted);

  useEffect(() => {
    load({});
  }, [currentPage]);

  const search = (policy) => {
    // alert(policy);
    load({ policy_no, broker, product_id, firstDay, lastDay });
  };

  async function load({}) {
    setLoading(true);
    try {
      const response = await api.get(`/policies`, {
        params: {
          firstDay,
          lastDay,
        },
      });

      console.log("AVAV", response.data);

      setData(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      name: "Cliente",
      selector: (row) => <div>{row?.client?.CLI_InsuredFullName}</div>,
      sortable: true,
    },
    {
      name: "Número de Apólice",
      maxWidth: "160px",
      allowOverflow: true,
      selector: (row) => <div>{row?.P_OtherPolicyNo}</div>,
    },
    {
      name: "Data de Contactado",
      allowOverflow: true,
      selector: (row) => <div>{row?.contacted?.date}</div>,
    },
    {
      name: "Hora de Contacto",
      allowOverflow: true,
      selector: (row) => <div>{row?.contacted?.time}</div>,
    },
    {
      name: "Pessoa responsavel",
      maxWidth: "140px",
      allowOverflow: true,
      selector: (row) => <div>{row?.contacted?.contacted_by}</div>,
    },
    {
      name: "Renovacao confirmada",
      maxWidth: "40px",

      selector: (row) => (
        <div>{row?.contacted?.confirmed === 2 ? "Sim" : "Nao"}</div>
      ),
    },
  ];

  const contactedPoliciesCount = data.filter(
    (policy) => policy.contacted !== null
  ).length;

  const startDate = new Date("1800-12-28");
  const first_selectedTime = new Date(firstDay);
  const last_selectedTime = new Date(lastDay);

  // Calculate the difference in milliseconds
  const differenceFirstInMs = first_selectedTime - startDate;
  const differenceLastInMs = last_selectedTime - startDate;

  // Convert milliseconds to days
  const from_date = Math.floor(
    differenceFirstInMs / (1000 * 60 * 60 * 24)
  );
  const to_date = Math.floor(
    differenceLastInMs / (1000 * 60 * 60 * 24)
  );

  return (
    <Fragment>
      <Row>
        {/* Filters */}
        <Row className="flex justify-content-between">
          <div className=" flex flex-col ">
            <h6 className="">Dashboard</h6>
          </div>
        </Row>

        {/* Totals row */}
        <Row className="my-2 text-left">
          <Col md="3">
            <Card
              className="px-4"
              style={{
                borderRadius: "4px",
                backgroundColor: "#F8F9FB",
                border: "1px solid #D4D9DD",
              }}
            >
              <h6 className="pt-3 px-2 " style={{ fontSize: "13px" }}>
                Clientes Contactado {contactedPoliciesCount}
              </h6>

              <h6 className="fw-bold px-2" style={{ fontSize: "17px" }}></h6>
              <Row>
                <Col md="12">
                  <Progress multi style={{ borderRadius: "0px" }}>
                    <Progress
                      bar
                      color="danger"
                      value={(contactedPoliciesCount / data.length) * 100}
                    ></Progress>
                    <Progress
                      bar
                      style={{ backgroundColor: "#D4D9DD" }}
                      value={0}
                    ></Progress>
                  </Progress>
                </Col>
              </Row>
              <h6
                className="d-flex justify-content-between"
                style={{ marginTop: "10px" }}
              >
                <small style={{ fontSize: "11px" }}>
                  % de contactado:
                  <strong>
                    {/* {formatCurrency(
                      sales.reduce((prev, { total_price }) => {
                        return prev + parseFloat(total_price);
                      }, 0)
                    )} */}
                  </strong>
                </small>
                <span style={{ fontSize: "11px" }}>
                  <strong>
                    {formatCurrency(contactedPoliciesCount / data.length)}%
                  </strong>
                </span>
              </h6>
            </Card>
          </Col>
          <Col md="5">
            <Card
              className="px-4 h-100 gap-2"
              style={{
                borderRadius: "4px",
                backgroundColor: "#F8F9FB",
                border: "1px solid #D4D9DD",
              }}
            >
              <h6 className="pt-3 px-2" style={{ fontSize: "13px" }}>
                Período de
                <span style={{ marginLeft: "6px" }}>{firstDay}</span> para{" "}
                <span style={{ marginLeft: "6px" }}>{lastDay}</span>
              </h6>

              <h6
                className="fw-bold px-2"
                style={{
                  fontSize: "17px",
                  lineHeight: "0px",
                  color: "#F8F9FB",
                }}
              >
                000
              </h6>
              <h6 className="d-flex justify-content-between gap-4">
                <Input
                  className=""
                  onChange={(e) => {
                    setFirstDay(e.target.value);
                  }}
                  style={{ fontSize: "13px", width: "300px" }}
                  type="date"
                />
                <Input
                  className=""
                  onChange={(e) => {
                    setLastDay(e.target.value);
                  }}
                  style={{ fontSize: "13px", width: "300px" }}
                  type="date"
                />
              </h6>
            </Card>
          </Col>
          <Col md={user.position === "ADMIN" ? "2" : "3"}>
            <Card
              className="px-4 h-100 gap-2"
              style={{
                borderRadius: "4px",
                backgroundColor: "#F8F9FB",
                border: "1px solid #D4D9DD",
              }}
            >
              <h6 className="pt-3 px-2" style={{ fontSize: "13px" }}>
                Renovações deste periodo
              </h6>

              <h6
                className="fw-bold px-2"
                style={{ fontSize: "17px", lineHeight: "0px" }}
              >
                {data.length}
              </h6>
              <br />
              <Button
                outline
                style={{ border: "1px solid red", color: "red" }}
                block
                className="text-uppercase btn-sm mb-2"
                //   onClick={handleAddRow}
                target={`_blank`}
                href={`${baseURL}/api/printReport/${from_date}/${to_date}`}
              >
                <small className="ms-1">Baixa Report</small>
              </Button>
              <h6 style={{ fontSize: "11px", lineHeight: "0px" }}>
                {/* *Apenas activos */}
              </h6>
            </Card>
          </Col>
          {user.position === "ADMIN" ? (
            <Col md="2">
              <Card
                className="px-4 h-100 gap-2"
                style={{
                  borderRadius: "4px",
                  backgroundColor: "#F8F9FB",
                  border: "1px solid #D4D9DD",
                }}
              >
                <h6 className="pt-3 px-2" style={{ fontSize: "11px" }}>
                  Comissão
                </h6>

                <h6
                  className="fw-bold "
                  style={{ fontSize: "17px", lineHeight: "0px" }}
                >
                  3663
                </h6>
                <br />
                <h6 style={{ fontSize: "11px", lineHeight: "0px" }}>Período</h6>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
        {/* Search Filters */}
        <Row className="my-2 gap-0">
          {/* <Col sm="3">
            <Input
              bsSize="sm"
              type="text"
              placeholder="Numero da Apolice"
              style={{ height: "33px" }}
              onChange={(e) => {
                setPolicyNo(e.target.value);
              }}
            />
          </Col> */}
          <Col sm="2">
            <Card
              style={{ borderRadius: "4px", backgroundColor: "#e9ecef" }}
              className="d-flex px-2 flex-row align-items-center"
            >
              <div
                className="text-muted d-flex align-items-center fw-bold"
                style={{ fontSize: "13px" }}
              >
                Agente
              </div>

              <Input
                style={{
                  border: "none",
                  height: "33px",
                }}
                disabled
                onChange={(e) => {
                  setBroker(e.target.value);
                }}
                type="select"
                bsSize="sm"
              ></Input>
            </Card>
          </Col>
          <Col sm="4" className="d-flex flex-row gap-1">
            <Col sm="6">
              <Card
                style={{ borderRadius: "4px", backgroundColor: "#e9ecef" }}
                className="d-flex px-2 flex-row align-items-center"
              >
                <div
                  className="text-muted d-flex align-items-center fw-bold"
                  style={{ fontSize: "13px" }}
                >
                  Produto
                </div>

                <Input
                  style={{
                    border: "none",
                    height: "33px",
                  }}
                  disabled
                  type="select"
                  onChange={(e) => {
                    setProductId(e.target.value);
                  }}
                  bsSize="sm"
                >
                  <option value="">All</option>
                  <option value="1">Africa</option>
                  <option value="2">Europe</option>
                  <option value="3">Mundo (Pearl)</option>
                  <option value="4">Mundo (Diamond)</option>
                </Input>
              </Card>
            </Col>
          </Col>

          <Col sm="3" className="d-flex justify-content-between gap-1">
            <Button
              color="secondary"
              outline
              onClick={(e) => {
                search();
              }}
              className=""
              style={{
                fontSize: "13px",
                fontWeight: "600",
                color: "#D12421",
                border: "none",
                backgroundColor: "#fff",
              }}
            >
              <u>Search</u>
            </Button>
            <Button
              color="secondary"
              outline
              className=""
              style={{
                fontSize: "13px",
                border: "none",
                backgroundColor: "#fff",
                fontWeight: "600",
                color: "#54606C",
              }}
              type="reset"
            >
              Clear
            </Button>
          </Col>
        </Row>
        <Row className="gap-4 my-2">
          {/* Tables only row */}

          <DataTable
            style={{ width: "100%" }}
            columns={columns}
            noHeader
            data={data}
            pagination
            paginationPerPage={7}
            progressPending={loading}
          />
        </Row>
      </Row>
    </Fragment>
  );
};

export default Reports;
