import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { AiOutlineClose } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import Assign from "../Modals/Assign";
import { SweetAlert } from "../../../../components/Alerts";
import { api } from "../../../../service/api";
const Details = ({ data, setRefreshKey, refreshKey }) => {
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");
  const [clientDob, setClientDob] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [Edit, setEdit] = useState(false);

  useEffect(() => {
    setClientEmail(data.CLI_email);
    setClientName(data.CLI_InsuredFullName);
    setClientPhone(data.CLI_CellNumber);
    setAlternatePhone(data.CLI_TelephoneNumber);
    setClientDob(data.SMS_dob);
  }, [data]);

  const handleSubmit = () => {
    try {
      const response = api.put(`clients/${data.CLI_ClientNo}`, {
        clientEmail,
        clientPhone,
        alternatePhone,
        clientDob,
      });

      setRefreshKey(refreshKey + 1);
      SweetAlert({
        title: "Actualizado Com Sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      SweetAlert({
        title: "Ocorreu um erro interno ao tentar efectuar a operação",
        text: "Se o problema persistir contacte o suporte técnico.",
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div style={{ display: "inline-block", height: "25px" }}>
        <h6 style={{ padding: "5px" }}>
          <strong>Details</strong>
        </h6>
      </div>
      <div
        style={{ display: "inline-block", float: "right" }}
        className=" justify-content-end align-items-center bg-white "
      >
        <div className="d-flex">
          <Assign
            data={data}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
          />
          {Edit === false ? (
            <Button
              size="sm"
              outline
              style={{
                color: "#000",
                border: "1px solid #000",
                height: "30px",
              }}
              onClick={() => {
                setEdit(true);
              }}
              className=" btn-sm  px-4 ms-2"
            >
              Edit Details
            </Button>
          ) : (
            <Button
              size="sm"
              outline
              style={{
                color: "#000",
                border: "1px solid #000",
                height: "30px",
              }}
              onClick={() => {
                setEdit(false);
                handleSubmit();
              }}
              className=" btn-sm  px-4 ms-2"
            >
              Save
            </Button>
          )}
        </div>
      </div>

      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Assigned</Label>
            <p style={{ lineHeight: "0px", fontSize: "14px" }}>
              <strong>{data?.assigned?.user?.name}</strong>
            </p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Type</Label>
            <p style={{ lineHeight: "0px", fontSize: "14px" }}>
              <strong>{data.CLI_EntityType}</strong>
            </p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Last Modified</Label>
            <p style={{ lineHeight: "0px", fontSize: "14px" }}>
              <strong>{data.updated_at}</strong>
            </p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Location</Label>
            <p style={{ lineHeight: "0px", fontSize: "14px" }}>
              <strong>{data.CLI_PhysicalAddress3}</strong>
            </p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Address</Label>
            <p style={{ fontSize: "14px" }}>
              <strong>{data.CLI_PhysicalAddress1}</strong>
              <br />
              <strong>{data.CLI_PhysicalAddress2}</strong>
            </p>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label></Label>
            <p style={{ lineHeight: "0px", fontSize: "14px" }}>
              <strong></strong>
            </p>
          </FormGroup>
        </Col>
      </Row>

      <Row
        style={{
          backgroundColor: "#dee2e6",
          marginLeft: "0px",
          marginRight: "0px",
          padding: "5px",
          marginTop: "10px",
        }}
      >
        Contact
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Phone</Label>
            {Edit === false ? (
              <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                <strong>{data.CLI_CellNumber}</strong>
              </p>
            ) : (
              <Input
                value={clientPhone}
                onChange={(e) => {
                  setClientPhone(e.target.value);
                }}
                type="number"
                bsSize="sm"
              />
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Email</Label>
            {Edit === false ? (
              <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                <strong>{data.CLI_email}</strong>
              </p>
            ) : (
              <Input
                value={clientEmail}
                bsSize="sm"
                type="email"
                onChange={(e) => {
                  setClientEmail(e.target.value);
                }}
              />
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>
              Data de Nascimento (Mes-dia)
            </Label>
            {Edit === false ? (
              <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                <strong>{data.SMS_dob}</strong>
              </p>
            ) : (
              <Input
                value={clientDob}
                onChange={(e) => {
                  setClientDob(e.target.value);
                }}
                type="text"
                bsSize="sm"
              />
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col md={4}>
          <FormGroup>
            <Label style={{ fontSize: "12px" }}>Alternate Phone</Label>

            {Edit === false ? (
              <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                <strong>{data.CLI_TelephoneNumber}</strong>
              </p>
            ) : (
              <Input
                value={alternatePhone}
                onChange={(e) => {
                  setAlternatePhone(e.target.value);
                }}
                type="text"
                bsSize="sm"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Details;
