import React, { useEffect, useState } from "react";
import {
  Button,
  CardText,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../service/api";
import { SweetAlert } from "../../components/Alerts";
import { useAuth } from "../../context/AuthContext";
import {
  AiOutlinePoweroff,
  AiOutlineEyeInvisible,
  AiFillBell,
  AiOutlineEye,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { getUser } from "../../service/auth";

function ModalEdit({
  client_id,
  client_name,
  policyNumber,
  renewalDate,
  policy_id,
  contacted,
}) {
  const [modal, setModal] = useState(false);

  const user = getUser();

  useEffect(() => {
    if (contacted && contacted.length > 0) {
      setConfirmed(contacted[0]?.confirmed);
      setCover(contacted[0].cover);
    }
  }, [contacted]);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const [loading, setIsLoading] = useState(false);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [confirmed, setConfirmed] = useState("");
  const [cover, setCover] = useState("");
  const [premium, setPremium] = useState("");
  const [payment_terms, setPaymentTerms] = useState("");
  const [comment, setComment] = useState("");
  const handleSubmit = () => {
    try {
      const response = api.post(`contacted`, {
        policy_id,
        policy_no: policyNumber,
        date,
        time,
        cover,
        grail_date: renewalDate,
        premium,
        payment_terms,
        comment,
        confirmed,
        contacted_by: user.name,
      });

      SweetAlert({
        title: "Actualizado Com Sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      SweetAlert({
        title: "Ocorreu um erro interno ao tentar efectuar a operação",
        text: "Se o problema persistir contacte o suporte técnico.",
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        className="btn btn-white btn-sm border-0 rounded-0"
        onClick={toggle}
      >
        <AiOutlineEye size={22} />
      </button>

      <Modal isOpen={modal} toggle={toggle} style={{ width: "740px" }}>
        <ModalHeader>
          <h4 className="fw-700" style={{ fontSize: "18px" }}>
            Cliente contactado?{" "}
          </h4>
        </ModalHeader>

        <ModalBody>
          <Row></Row>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="memberprovider">Nome </Label>
                <h6>{client_name} </h6>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider"> Apolice </Label>
                <h6>{policyNumber} </h6>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Data </Label>
                <Input
                  value={contacted != null ? contacted.date : date}
                  disabled={contacted}
                  bsSize="sm"
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Hora </Label>
                <Input
                  value={contacted != null ? contacted.time : time}
                  disabled={contacted}
                  bsSize="sm"
                  type="email"
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Renovacao confirmada? </Label>
                <Input
                  value={contacted != null ? contacted.confirmed : confirmed}
                  bsSize="sm"
                  disabled={contacted}
                  type="select"
                  onChange={(e) => {
                    setConfirmed(e.target.value);
                  }}
                >
                  {" "}
                  <option></option>
                  <option value={2}>Sim</option>
                  <option value={1}>Nao</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Cobertura </Label>
                <Input
                  value={contacted != null ? contacted.cover : cover}
                  disabled={contacted}
                  bsSize="sm"
                  type="email"
                  onChange={(e) => {
                    setCover(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Premio Anual </Label>
                <Input
                  value={contacted != null ? contacted.premium : premium}
                  disabled={contacted}
                  bsSize="sm"
                  type="number"
                  onChange={(e) => {
                    setPremium(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Modalidade de Pagamento </Label>
                <Input
                  value={
                    contacted != null ? contacted.payment_terms : payment_terms
                  }
                  disabled={contacted}
                  bsSize="sm"
                  type="email"
                  onChange={(e) => {
                    setPaymentTerms(e.target.value);
                  }}
                >
                  <option value="Annual">Annual</option>
                  <option value="Semestral">Semestral</option>
                  <option value="Trimestral">Trimestral</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="memberprovider">Comment </Label>
                <Input
                  disabled={contacted}
                  value={contacted != null ? contacted.comment : comment}
                  bsSize="sm"
                  type="textarea"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#fff",
              color: "#747587",
              border: "none",
            }}
          >
            <span className="ms-1 me-1">Close </span>
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
              toggle();
            }}
            color="primary"
            className="btn-sm rounded"
            bssize="sm"
            style={{ width: "134px", marginLeft: "20px" }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalEdit;
