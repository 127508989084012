import React from "react";
import logo from "../../assets/logo.png";
import { MdLogout } from "react-icons/md";
import "./style.css";

const Header = () => {

  const handleSignOut = () => {
    const advice = "Are you sure you want to log out?";
    if (window.confirm(advice) === true) {
      window.location.href = 'http://apps.palmaseguros.tech';
    }
  }

  return (
    <div className="sticky-top bg-white px-4 py-2 d-flex justify-content-between align-items-center border-bottom">
      <div className="d-flex align-items-center gap-3 text-secondary">
        <img src={logo} alt="Palma Logo" width={120} />
        <span style={{ fontWeight: 500, fontSize: "1rem" }}>Gestão de notificações</span>
      </div>
      <div>
        <button type='button' onClick={handleSignOut} className="btn btn-sm btn-white text-secondary">
          <MdLogout size={15} />
          <span className="ms-2" style={{ fontWeight: 500 }}>Log Out</span>
        </button>
      </div>
    </div>
  );
};

export default Header;
