import "bootstrap/dist/css/bootstrap.css";
import "./assets/GlobalStyle.css";

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { SpinnerComponent } from "./components/Spinner";
import { AuthProvider } from "./context/AuthContext";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
