import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

import { AiOutlineClose, AiOutlineEye } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Communication from "../Modals/Communication";
import FollowUp from "../Modals/FollowUp";
const Communications = ({ contacted, policies, refreshKey, setRefreshKey }) => {
  return (
    <Fragment>
      <div style={{ display: "inline-block", height: "25px" }}>
        <h6 style={{ padding: "5px" }}>
          <strong>Details</strong>
        </h6>
      </div>
      <div
        style={{ display: "inline-block", float: "right" }}
        className=" justify-content-end align-items-center bg-white "
      >
        <div className="d-flex">
          <Communication
            policies={policies}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
          />
        </div>
      </div>

      <hr />

      {contacted &&
        contacted?.map((item, index) => {
          return (
            <div>
              <Row
                style={{
                  backgroundColor: "#dee2e6",
                  marginLeft: "0px",
                  marginRight: "0px",
                  padding: "5px",
                  marginTop: "10px",
                }}
              >
                <Col>Communication #{index + 1}</Col>
                <Col style={{ textAlign: "end" }}>
                  <FollowUp
                    policy_no={item.policy_no}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                    contact_id={item.id}
                  />

                  {/* <span style={{ marginRight: "20px" }}> Edit</span>

                  <span>Delete</span> */}
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col>
                  <FormGroup>
                    <Label style={{ fontSize: "12px" }}>Method</Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong>{item.contact_method}</strong>
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label style={{ fontSize: "12px" }}>Produto</Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong>{item.cover}</strong>
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Nº da apólice</Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong>{item.policy_no} </strong>
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label> Data</Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong>{item.date}</strong>
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col>
                  <FormGroup>
                    <Label style={{ fontSize: "12px" }}>
                      Renovação confirmada
                    </Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong>{item.confirmed === 2 ? "Sim" : "Nao"}</strong>
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label style={{ fontSize: "12px" }}>
                      {" "}
                      Data Pretendida de Renovacao
                    </Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong>{item.expected_date}</strong>
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label></Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong> </strong>
                    </p>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Prémio Anual</Label>
                    <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                      <strong> {item.premium}</strong>
                    </p>
                  </FormGroup>
                </Col>
              </Row>

              <table style={{ width: "100%", borderCollapse: "collapse" , border:'1px solid #ddddddd'}}>
                <thead
                  style={{
                    backgroundColor: "#000",
                    paddingLeft:'20px',
                    border: "1px solid #dddddd",
                  }}
                >
                  <th
                    style={{
                      backgroundColor: "#fff",
                      width:'150px',
                      textAlign: "left",
                      padding: "4px",
                      fontSize: "12px",
                    }}
                  >
                    # Follow Up
                  </th>
                  <th
                    style={{
                      backgroundColor: "#fff",
                      width:'150px',
                      textAlign: "left",
                      padding: "4px",
                      fontSize: "12px",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      backgroundColor: "#fff",
                      width:'150px',
                      textAlign: "left",
                      padding: "4px",
                      fontSize: "12px",
                    }}
                  >
                    Time
                  </th>
                  <th
                    style={{
                      backgroundColor: "#fff",

                      textAlign: "left",
                      padding: "4px",
                      fontSize: "12px",
                    }}
                  >
                    Comment
                  </th>
                </thead>

                {item.followups.map((el, index) => (
                  <tr style={{border:'1px solid #dddddd'}}>
                    <td>{index + 1}</td>
                    <td>{el.date}</td>
                    <td>{el.time}</td>
                    <td>{el.comment}</td>
                  </tr>
                ))}
              </table>
            </div>
          );
        })}
    </Fragment>
  );
};

export default Communications;
