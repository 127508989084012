import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { BsSearch } from "react-icons/bs";
import { BiMailSend } from "react-icons/bi";
import { TbCalendarDue } from "react-icons/tb";
import { SweetAlert } from "../../components/Alerts";
import "./index.css";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { api } from "../../service/api";
import { FaChevronDown } from "react-icons/fa";

const Category = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const handleResend = () => {
    toggle();
    SweetAlert({
      title: "Concluded!",
      icon: "success",
      text: "Unsent emails to Overdue sent successfully.",
    });
  };

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [policy_no, setPolicyNo] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchPage, setFetchPage] = useState(true);

  const [activeButton, setActiveButton] = useState("");

  const handleButtonClick = (category) => {
    setActiveButton(category);
  };

  useEffect(() => {
    setFetchPage(true);
    loadSent();
  }, [currentPage, activeButton]);

  const search = () => {
    loadSent({ policy_no, start_date, end_date });
  };
  const loadSent = async () => {
    let page = fetchPage ? Number(currentPage) + 1 : 1;
    setLoading(true);
    try {
      const response = await api.get("/sent", {
        params: {
          // paginate: true,
          // page,
          // per_page: 7,
          category: activeButton,
          policy_no,
          start_date,
          end_date,
        },
      });
      setData(response.data);
      console.log("resp", response.data);
      setTotalRows(response.data.total);
    } catch (error) {
    } finally {
      setLoading(false);
      setFetchPage(false);
    }
  };

  const NotifyHappyBirthday = () => {
    try {
      const response = api.post(`send_happyBirthday`);

      SweetAlert({
        title: "Enviado Com Sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      SweetAlert({
        title: "Ocorreu um erro interno ao tentar efectuar a operação",
        text: "Se o problema persistir contacte o suporte técnico.",
        icon: "warning",
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "Nome do Cliente",
      maxWidth: "240px",

      selector: (row) => <span>{row.policy?.client?.CLI_InsuredFullName}</span>,
    },
    {
      name: "Email",
      selector: (row) => <span>{row.email}</span>,
    },
    {
      name: "Detalhes do contacto",
      allowOverflow: true,
      selector: (row) => (
        <div>
          <span>{row.policy?.client?.CLI_CellNumber}</span>
        </div>
      ),
    },
    {
      name: "Numero da apolice",

      selector: (row) => <span>{row.policy_no}</span>,
    },

    {
      name: "Tipo de notificação",

      selector: (row) => {
        return <span>{row.type_enum}</span>;
      },
    },

    {
      name: "Metodo de notificação",

      selector: (row) => {
        return <span>{row.notification_type}</span>;
      },
    },
    {
      name: "Data",
      selector: (row) => <span>{row.date}</span>,
    },
    // {
    //   name: "Estado",
    //   allowOverflow: true,

    //   cell: (row) => {
    //     return (
    //       <div className="d-flex flex-row justify-content-end align-items-center ">
    //         {row.status}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   name: "Ações",
    //   allowOverflow: true,

    //   cell: (row) => {
    //     return (
    //       <div>
    //         <button
    //           className="btn btn-white btn-sm border-0 rounded-0"
    //           onClick={toggle}
    //         >
    //           <AiOutlineEye size={22} />
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];

  //THIS STYLES THE DATA TABLE
  const customStyles = {
    rows: {
      style: {
        fontWeight: "500",
        border: "1px solid #D4D9DD",
        fontSize: "12px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
        borderTop: "1px solid #D4D9DD",
        minHeight: "0px !important",
        color: "#000",
        backgroundColor: "#f2f2f2",
        fontWeight: "500",
      },
    },
  };

  // const search = () => {
  //   const filtered = feeds.filter((feed) =>
  //     feed.title.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   setFilteredFeeds(filtered);
  // };

  return (
    <Fragment>
      <h4 className="mb-4">Categories</h4>
      <div className="mb-4">
        <button
          className={`btn btn-white text-secondary me-2 menu_category rounded-0 ${
            activeButton === "" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("")}
        >
          All
        </button>
        <button
          className={`btn btn-white text-secondary me-2 menu_category rounded-0 ${
            activeButton === "RENOVATION" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("RENOVATION")}
        >
          Renovação
        </button>
        <button
          className={`btn btn-white text-secondary me-2 menu_category rounded-0 ${
            activeButton === "BIRTHDAY" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("BIRTHDAY")}
        >
          Aniversário
        </button>
        <button
          className={`btn btn-white text-secondary me-2 menu_category rounded-0 ${
            activeButton === "BILLING" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("BILLING")}
        >
          Cobrança
        </button>
        {/* <button
          className={`btn btn-white text-secondary menu_category rounded-0 ${
            activeButton === "RECEIPT" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("RECEIPT")}
        >
          Receipt
        </button> */}
      </div>
      <div className="mb-3 d-flex gap-3">
        <div>
          <label htmlFor="search" className="form-label">
            Search
          </label>
          <div className="input-group">
            <span className="input-group-text bg-white" id="search">
              <BsSearch />
            </span>
            <input
              type="text"
              onChange={(e) => {
                setPolicyNo(e.target.value);
              }}
              className="form-control form-control-sm"
              placeholder="PALMA/PVT/001-000"
            />
          </div>
        </div>
        <div>
          <label htmlFor="start_date" className="form-label">
            Start Date
          </label>
          <div className="input-group">
            <span className="input-group-text bg-white" id="start_date">
              <TbCalendarDue />
            </span>
            <input
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              type="date"
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
        </div>
        <div>
          <label htmlFor="end_date" className="form-label">
            End Date
          </label>
          <div className="input-group">
            <span className="input-group-text bg-white" id="end_date">
              <TbCalendarDue />
            </span>
            <input
              type="date"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              className="form-control form-control-sm"
              placeholder="DD/MM/YYYY"
            />
          </div>
        </div>
        <div>
          <label htmlFor="end_date" className="form-label text-white">
            .
          </label>
          <div className="input-group">
            <Button
              onClick={() => {
                search();
              }}
              color="danger"
              outline
              size="sm"
            >
              Search
            </Button>
          </div>
        </div>
        {/* <div>
          <label htmlFor="end_date" className="form-label text-white">
            .
          </label>
          <div className="input-group">
            <Button color="danger" size="sm">
              Send Unsent: Billing
            </Button>
          </div>
        </div> */}
      </div>

      <DataTable
        style={{ width: "100%" }}
        columns={columns}
        noHeader
        data={data}
        pagination
        customStyles={customStyles}
        paginationPerPage={10}
        progressPending={loading}
      />

      <Modal isOpen={modal} toggle={toggle}>
        <div className="text-center p-3 border-bottom">
          Resend Email Message
        </div>
        <ModalBody>
          <p style={{ fontWeight: 500 }}>
            Are you sure you want to resend this email?
          </p>
          <p className="text-muted">Renewal email message:</p>
          <p className="text-muted">To Mikael Rodriguez Santos</p>
          <p className="text-muted">Last sent: 15/09/2023 15:26</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" size="sm" outline onClick={toggle}>
            No: Cancel
          </Button>{" "}
          <Button color="primary" size="sm" onClick={handleResend}>
            Yes: Send
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Category;
