import React, { useEffect, useState } from "react";
import {
  Button,
  CardText,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../service/api";

import {
  AiOutlinePoweroff,
  AiOutlineEyeInvisible,
  AiFillBell,
  AiOutlineEye,
  AiOutlineAlert,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { useAuth } from "../../../../context/AuthContext";
import { SweetAlert } from "../../../../components/Alerts";

function SendNotif({ client_name, policies }) {
  const [modal, setModal] = useState(false);

  const { user } = useAuth();

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const [loading, setIsLoading] = useState(false);

  const [method, setMethod] = useState("");
  const [policy_id, setPolicyId] = useState("");
  const [notifType, setNotifType] = useState("");

  const handleSubmit = () => {
    try {
      const response = api.post(`send_custom_notif`, {
        policy_id,
        method,
        notifType,
      });

      SweetAlert({
        title: "Enviado Com Sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      SweetAlert({
        title: "Ocorreu um erro interno ao tentar efectuar a operação",
        text: "Se o problema persistir contacte o suporte técnico.",
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        outline
        style={{ color: "#000", border: "1px solid #000" }}
        onClick={toggle}
        size="sm"
      >
        Send Communication
      </Button>

      <Modal isOpen={modal} toggle={toggle} style={{ width: "440px" }}>
        <ModalHeader></ModalHeader>

        <ModalBody>
          <h4 className="fw-700" style={{ fontSize: "13px" }}>
            Envia Mensagem
          </h4>

          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="memberprovider">Nome </Label>
                <h6>{client_name}</h6>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  {" "}
                  Apolice{" "}
                </Label>
                <Input
                  bsSize="sm"
                  type="select"
                  onChange={(e) => {
                    setPolicyId(e.target.value);
                  }}
                >
                  <option></option>
                  {policies?.map((item) => (
                    <option value={item.P_PolicyNo}>
                      {item.P_OtherPolicyNo}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Col>
            <FormGroup>
              <Label for="memberprovider">Metodo de Notificacao</Label>
              <Input
                value={method}
                bsSize="sm"
                type="select"
                onChange={(e) => {
                  setMethod(e.target.value);
                }}
              >
                <option></option>
                <option value={"EMAIL"}>Email</option>
                <option value={"SMS"}>SMS</option>
              </Input>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label for="memberprovider">Tipo de Notificacao</Label>
              <Input
                value={notifType}
                bsSize="sm"
                type="select"
                onChange={(e) => {
                  setNotifType(e.target.value);
                }}
              >
                <option></option>
                <option value={"BILLING"}>Aviso de Cobranca</option>
                <option value={"RENOVATION"}>Aviso de Renovacao</option>
              </Input>
            </FormGroup>
          </Col>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#fff",
              color: "#747587",
              border: "none",
            }}
          >
            <span className="ms-1 me-1">Close </span>
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
              toggle();
            }}
            color="primary"
            className="btn-sm rounded"
            bssize="sm"
            style={{ width: "134px", marginLeft: "20px" }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default SendNotif;
