import axios from "axios";
import { getToken } from "./auth";

//LOCAL SERVER
    //  export const baseURL = "http://localhost:8002";

   //LIVE SERVER
     export const baseURL = "https://emails-backend.palmaseguros.tech";


const api = axios.create({
  baseURL: baseURL + "/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
