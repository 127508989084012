import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { FaChevronDown, FaDownload } from "react-icons/fa";

import { AiOutlineClose, AiOutlineEye } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import DataTable from "react-data-table-component";

import { Link, useParams } from "react-router-dom";
import { api, baseURL } from "../../service/api";
import { formatDate } from "../../utility/FormatDate";
import { formatCurrency } from "../../utility/FormatCurrency";
const Policy = ({}) => {
  const { id } = useParams();

  const columns = [
    {
      name: "#",
      maxWidth: 40,
      selector: (row, index) => <span>{index + 1}</span>,
      sortable: true,
    },
    {
      name: "Data ",
      allowOverflow: true,
      selector: (row) => <span>{formatDate(row.SP_ReceiptDate)}</span>,
    },

    {
      name: "Numero do documento",
      allowOverflow: true,
      selector: (row) => (
        <span>
          {row.SP_Description === "PR" ? "CT0000" : "RC000"}
          {row.SP_UWYear2}
        </span>
      ),
    },

    {
      name: "Valor",
      allowOverflow: true,
      selector: (row) => (
        <span>{formatCurrency(Math.abs(row.SP_ReceivedAmount))}</span>
      ),
    },

    {
      name: "",
      allowOverflow: true,
      selector: (row) => (
        <div>
          {row.SP_Description === "PR" ? (
            <Button
              outline
              color="danger"
              // disabled={memberTransactions.length > 0 ? false : true}
              target={`_blank`}
              href={`${baseURL}/api/printDebit/${row.SP_SPolicyNo}`}
              className="text-uppercase btn-sm mb-2"
            >
              <FaDownload size={12} />
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px !important", // override the row height
        fontSize: "11px",
        whiteSpace: "pre",
        paddingTop: "0px",
        paddingLeft: "8px",
        paddingBottom: "0px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "11px",
        marginTop: "20px",
        minHeight: "0px !important",
        color: "#818486",
        fontWeight: "500",
      },
    },
  };

  const [data, setData] = useState([]);

  const [name, setName] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadPolicy = async () => {
      if (id) {
        try {
          const response = await api.get(`/policies/${id}`);

          setData(response.data);

          setName(response.data?.client?.assigned?.user?.name);
        } catch (error) {
          console.error(error.response.data);
        }
      }
    };
    loadPolicy();
  }, [id]);

  return (
    <Fragment>
      <Card>
        <CardBody>
          {" "}
          <h6>
            <strong>{data.client && data?.client?.CLI_InsuredFullName}</strong>
          </h6>
          <Row>
            <Col md={1}>
              <FormGroup>
                <Label style={{ fontSize: "12px" }}>Produto</Label>
                <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                  <strong>{data.P_OtherCode}</strong>
                </p>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label style={{ fontSize: "12px" }}>Nº da apólice</Label>
                <p style={{ lineHeight: "0px", fontSize: "14px" }}>
                  <strong>{data.P_OtherPolicyNo}</strong>
                </p>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row style={{ marginTop: "20px" }}>
        <Col md={4}>
          <Card>
            <CardHeader>Detalhes de Apolice</CardHeader>
            <CardBody>
              <FormGroup>
                <strong style={{ color: "#868686" }}>
                  <span> Estado</span>
                </strong>

                <span style={{ float: "right" }}>
                  <strong>
                    {data.P_Status === "A" ? "Activo" : "Cancelado"}
                  </strong>
                </span>
              </FormGroup>
              <FormGroup>
                <strong style={{ color: "#868686" }}>
                  <span> data de expiração</span>
                </strong>

                <span style={{ float: "right" }}>
                  <strong>{formatDate(data.P_RenewalDate)}</strong>
                </span>
              </FormGroup>
              <FormGroup>
                <strong style={{ color: "#868686" }}>
                  <span> Agente responsavel</span>
                </strong>

                <span style={{ float: "right" }}>
                  <strong>{name}</strong>
                </span>
              </FormGroup>

              <hr />
              <FormGroup>
                <strong style={{ color: "#868686" }}>
                  <span> Premio Anual</span>
                </strong>

                <span style={{ float: "right" }}>
                  <strong>{formatCurrency(data.P_Premium)}</strong>
                </span>
              </FormGroup>

              <FormGroup>
                <strong style={{ color: "#868686" }}>
                  <span> Balanco do Cliente</span>
                </strong>

                <span style={{ float: "right" }}>
                  <strong>{formatCurrency(data.P_PremiumBalance)}</strong>
                </span>
              </FormGroup>
              <FormGroup>
                <strong style={{ color: "#868686" }}>
                  <span> Corretor</span>
                </strong>

                <span style={{ float: "right" }}>
                  <strong>{data.broker && data.broker?.B_BrokerName}</strong>
                </span>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>

        <Col md={8}>
          <Card>
            <CardHeader>Accounts</CardHeader>
            <CardBody>
              {" "}
              <DataTable columns={columns} data={data?.s_policies} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Policy;
