import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

import { AiOutlineClose, AiOutlineEye } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import SendNotif from "../Modals/SendNotif";
const EmailSent = ({ client_name, emails, policies }) => {
  const columns = [
    {
      name: "Subject",
      maxWidth: "240px",

      selector: (row) => <span>{row.type}</span>,
    },
    {
      name: "Tipo ",
      allowOverflow: true,
      selector: (row) => (
        <span>{row.notification_number === "0" ? "Manual" : "Automatico"}</span>
      ),
    },
    {
      name: "Email/Contacto",
      allowOverflow: true,
      selector: (row) => <span>{row.email?row.email:row.cell_no}</span>,
    },
    {
      name: "Nº da apólice",
      allowOverflow: true,
      selector: (row) => <span>{row.policy_no}</span>,
    },
    {
      name: "Data",
      allowOverflow: true,
      selector: (row) => <span>{row.created_at}</span>,
    },
    {
      name: "Ações",
      allowOverflow: true,
      maxWidth: "140px",
      cell: (row) => {
        return (
          <div className="d-flex flex-row justify-content-end align-items-center ">
            <FormGroup>
              <Link to={`/plans/edit/${row.id}`}>
                <Button
                  className={`btn-icon btn-sm rounded-circle mx-1`}
                  style={{ backgroundColor: "#fff", border: "1px solid #fff" }}
                >
                  <AiOutlineEye size={18} color="black" />
                </Button>
              </Link>
            </FormGroup>

            {/* <FormGroup>
                  <Button
                    className={`btn-icon btn-sm rounded-circle `}
                    // color="danger"
                    style={{ backgroundColor: "#fff", border: "1px solid #fff" }}
                    onClick={() => handleDeletePlan(row.id)}
                  >
                    <FaTrash size={10} color="black" />
                  </Button>
                </FormGroup> */}
          </div>
        );
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px !important", // override the row height
        fontSize: "12px",
        whiteSpace: "pre",
        paddingTop: "0px",
        paddingLeft: "8px",
        paddingBottom: "0px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
        marginTop: "20px",
        minHeight: "0px !important",
        color: "#818486",
        fontWeight: "500",
      },
    },
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <h6 style={{ padding: "5px", lineHeight: "0px" }}>
            <strong>Emails Sent</strong>
          </h6>
        </Col>
        <Col style={{ textAlign: "end" }}>
          <SendNotif client_name={client_name} policies={policies} />
        </Col>
      </Row>

      <hr />
      <Row>
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={emails}
        />
      </Row>
    </Fragment>
  );
};

export default EmailSent;
