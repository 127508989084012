import React, { useEffect, useState } from "react";
import {
  Button,
  CardText,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../service/api";
import { SweetAlert } from "../../../../components/Alerts";
import { useAuth } from "../../../../context/AuthContext";
import {
  AiOutlinePoweroff,
  AiOutlineEyeInvisible,
  AiFillBell,
  AiOutlineEye,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { getUser } from "../../../../service/auth";
import { formatDate } from "../../../../utility/FormatDate";
import { formatCurrency } from "../../../../utility/FormatCurrency";

function FollowUp({ policy_no, refreshKey, setRefreshKey, contact_id }) {
  const [modal, setModal] = useState(false);

  const user = getUser();

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const [loading, setIsLoading] = useState(false);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    try {
      const response = api.post(`followup`, {
        contacted_id: contact_id,
        date,
        time,
        comment,
        contacted_by: user.name,
      });

      setRefreshKey(refreshKey + 1);
      SweetAlert({
        title: "Actualizado Com Sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      SweetAlert({
        title: "Ocorreu um erro interno ao tentar efectuar a operação",
        text: "Se o problema persistir contacte o suporte técnico.",
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <span onClick={toggle} style={{ marginRight: "20px", cursor: "pointer" }}>
        Follow up
      </span>
      <Modal isOpen={modal} toggle={toggle} style={{ width: "740px" }}>
        <ModalHeader>
          <h5
            className="fw-700"
            style={{ fontSize: "18px", textAlign: "center" }}
          >
            New communication{" "}
          </h5>
        </ModalHeader>

        <ModalBody>
          <Row style={{ marginTop: "6px", height: "57px" }}>
            {" "}
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  Produto{" "}
                </Label>
                <Input
                  bsSize="sm"
                  disabled
                  type="select"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  {" "}
                  Apolice{" "}
                </Label>
                <Input bsSize="sm" value={policy_no} disabled />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  Data
                </Label>
                <Input
                  bsSize="sm"
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  Hora
                </Label>
                <Input
                  value={time}
                  bsSize="sm"
                  type="text"
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  Comment{" "}
                </Label>
                <Input
                  value={comment}
                  bsSize="sm"
                  type="textarea"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#fff",
              color: "#747587",
              border: "none",
            }}
          >
            <span className="ms-1 me-1">Close </span>
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
              toggle();
            }}
            color="primary"
            className="btn-sm rounded"
            bssize="sm"
            style={{ width: "134px", marginLeft: "20px" }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FollowUp;
