import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardBody,
  Row,
  CardHeader,
  Card,
  Col,
  Table,
} from "reactstrap";
import { BsSearch } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { TbCalendarDue } from "react-icons/tb";

import { api } from "../../service/api";
import DataTable from "react-data-table-component";

import { FaChevronDown } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { formatCurrency } from "../../utility/FormatCurrency";
import ModalEdit from "./ModalEdit";
const Renovations = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [client_name, setClientName] = useState("");
  const [policy_no, setPolicyNo] = useState("");
  const [date, setDate] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchPage, setFetchPage] = useState(true);
  const [contacted, setContacted] = useState([]);

  useEffect(() => {
    setFetchPage(true);
    loadClients();
    LoadContacted();
  }, [currentPage]);

  const search = () => {
    loadClients({ client_name, policy_no, date });
  };

  const loadClients = async () => {
    let page = fetchPage ? Number(currentPage) + 1 : 1;
    setLoading(true);
    try {
      const response = await api.get("/policies", {
        params: {
          paginate: true,
          page,
          client_name,
          policy_no,
          date,
        },
      });
      setData(response.data.data);
      console.log("response", response.data.data);
      setTotalRows(response.data.total);
    } catch (error) {
    } finally {
      setLoading(false);
      setFetchPage(false);
    }
  };

  const LoadContacted = async () => {
    setLoading(true);
    try {
      const response = await api.get("/contacted");
      setContacted(response.data);
      console.log("response", response.data);
    } catch (error) {
    } finally {
      setLoading(false);
      setFetchPage(false);
    }
  };

  const columns = [
    {
      name: "Numero de Apolice",

      allowOverflow: true,
      selector: (row) => {
        return <span>{row.P_OtherPolicyNo}</span>;
      },
    },
    {
      name: "Nome do Cliente",
      maxWidth: "240px",

      selector: (row) => <span>{row.client?.CLI_InsuredFullName}</span>,
    },

    {
      name: "Detalhes do contacto",
      allowOverflow: true,
      selector: (row) => (
        <div>
          <span>{row.client?.CLI_CellNumber}</span>
        </div>
      ),
    },
    {
      name: "Corrector",

      selector: (row) => (
        <div>
          <span>
            {row.P_BrokerCode == "8" ? "Directo" : row.broker?.B_BrokerName}
          </span>
        </div>
      ),
    },
    {
      name: "Data de renovação",

      selector: (row) => {
        const grailDate = new Date("1800-12-28");

        grailDate.setDate(grailDate.getDate() + row.P_RenewalDate);

        const formattedDate = `${grailDate.getFullYear()}-${String(
          grailDate.getMonth() + 1
        ).padStart(2, "0")}-${String(grailDate.getDate()).padStart(2, "0")}`;

        return <span>{formattedDate}</span>;
      },
    },
    {
      name: "Ações",
      allowOverflow: true,

      cell: (row) => {
        return (
          <div>
            {" "}
            <span style={{ display: "inline-block" }}>
              <ModalEdit
                contacted={row.contacted}
                client_id={row.client?.CLI_ClientNo}
                client_name={row.client?.CLI_InsuredFullName}
                renewalDate={row.P_RenewalDate}
                policyNumber={row.P_OtherPolicyNo}
                policy_id={row.P_PolicyNo}
              />{" "}
            </span>
            <span style={{ display: "inline-block" }}> </span>
          </div>
        );
      },
    },
  ];

  //THIS STYLES THE DATA TABLE
  const customStyles = {
    rows: {
      style: {
        fontWeight: "500",
        border: "1px solid #D4D9DD",
        fontSize: "12px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
        borderTop: "1px solid #D4D9DD",
        minHeight: "0px !important",
        color: "#000",
        backgroundColor: "#f2f2f2",
        fontWeight: "500",
      },
    },
  };

  const handlePagination = (page) => {
    setFetchPage(true);
    setCurrentPage(page.selected);
  };

  const contactFiltered = contacted.filter((item) => item.confirmed === 2);

  const CustomPagination = () => {
    const count = Number((totalRows / 10).toFixed(0));

    return (
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel="..."
        pageCount={count || 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        activeClassName="active"
        forcePage={currentPage}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName={
          "pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
        }
      />
    );
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <Card style={{ borderRadius: "4px" }}>
            <h6 className="pt-3 px-2" style={{ fontSize: "13px" }}>
              Renovações de Dezembro 2023 e Janeiro 2024
            </h6>

            <h6 className="fw-bold px-2" style={{ fontSize: "17px" }}>
              {totalRows}
            </h6>
          </Card>
        </Col>
        <Col>
          <Card style={{ borderRadius: "4px" }}>
            <h6 className="pt-3 px-2" style={{ fontSize: "13px" }}>
              renovações confirmadas
            </h6>

            <h6 className="fw-bold px-2" style={{ fontSize: "17px" }}>
              {contactFiltered.length}
            </h6>
          </Card>
        </Col>
        <Col>
          <Card style={{ borderRadius: "4px" }}>
            <h6 className="pt-3 px-2" style={{ fontSize: "13px" }}>
              Clientes contactado
            </h6>

            <h6 className="fw-bold px-2" style={{ fontSize: "17px" }}>
              {contacted.length}
            </h6>
          </Card>
        </Col>
      </Row>
      <br />
      <div className="mb-3 d-flex gap-3">
        <div>
          <label htmlFor="search" className="form-label">
            Search
          </label>
          <div className="input-group">
            <span className="input-group-text bg-white" id="search">
              <BsSearch />
            </span>
            <input
              onChange={(e) => {
                setClientName(e.target.value);
              }}
              type="text"
              className="form-control form-control-sm"
              placeholder="nome do cliente"
            />
          </div>
        </div>
        <div>
          <label htmlFor="start_date" className="form-label">
            Policy
          </label>
          <Input
            className="form-control form-control-sm"
            type="text"
            placeholder="numero da apolice"
            onChange={(e) => {
              setPolicyNo(e.target.value);
            }}
          />
        </div>

        <div>
          <label htmlFor="start_date" className="form-label">
            Data da renovação
          </label>
          <Input
            className="form-control form-control-sm"
            type="date"
            placeholder=""
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>
        {/* <div>
          <label htmlFor="end_date" className="form-label">
            Policy Status
          </label>
          <select
            className="form-select form-select-sm"
            aria-label="Default select example"
          >
            <option selected>All</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div> */}
        <div>
          <label htmlFor="end_date" className="form-label text-white">
            .
          </label>
          <div className="input-group">
            <Button
              onClick={() => {
                search();
              }}
              color="danger"
              outline
              size="sm"
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <DataTable
        noHeader
        pagination
        columns={columns}
        customStyles={customStyles}
        className="react-dataTable"
        sortIcon={<FaChevronDown size={6} />}
        data={data}
        progressPending={loading}
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        paginationComponent={CustomPagination}
      />
    </Fragment>
  );
};

export default Renovations;
