import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./layouts/index";
import Login from "./pages/Login";
import { useAuth } from "./context/AuthContext";

import Home from "./pages/Home";
import Client from "./pages/Client";
import Category from "./pages/Category";
import Sent from "./pages/Sent";
import Renovations from "./pages/Renovations";
import EditClient from "./pages/Client/Edit";
import Policy from "./pages/Client/Policy";
import Reports from "./pages/Reports";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/dashboard"} />} />

      {/*  =================Home ROUTES=================== */}
      <Route
        path="/dashboard"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />

      {/*  =================Client ROUTES=================== */}
      <Route
        path="/clients"
        element={
          <Layout>
            <Client />
          </Layout>
        }
      />

      <Route
        path="/clients/:id"
        element={
          <Layout>
            <EditClient />
          </Layout>
        }
      />

      <Route
        path="/policy/:id"
        element={
          <Layout>
            <Policy />
          </Layout>
        }
      />

      {/*  =================Category ROUTES=================== */}
      <Route
        path="/categories"
        element={
          <Layout>
            <Category />
          </Layout>
        }
      />
      {/*  =================Sent ROUTES=================== */}
      <Route
        path="/sent"
        element={
          <Layout>
            <Sent />
          </Layout>
        }
      />

      <Route
        path="/renovations"
        element={
          <Layout>
            <Renovations />
          </Layout>
        }
      />

      {/*  =================REPORTS ROUTES=================== */}

      <Route
        path="/reports"
        element={
          <Layout>
            <Reports />
          </Layout>
        }
      />
      <Route path="/auth/login" element={<Login />} />
      <Route path="*" element={<h1>Resource Not Found</h1>} />
    </Routes>
  );
};

const RequireAuth = ({ children }) => {
  const { authenticated } = useAuth();
  console.log("RequireAuth authenticated", authenticated);
  return authenticated === true ? (
    <Layout>{children}</Layout>
  ) : (
    <Navigate to="/" replace />
  );
};

export default Router;
