import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { api } from "../../../service/api";

import { FaCheckCircle } from "react-icons/fa";
import { renderToString } from "react-dom/server";
import { ContentForm, HeaderForm } from "../../../components/FormSteps";
import Swal from "sweetalert2";
import "./index.css";
import Details from "./Tabs/Details";
import Policies from "./Tabs/Policies";
import EmailSent from "./Tabs/EmailSent";
import Communications from "./Tabs/Communications";
import Notes from "./Tabs/Notes";

const EditClient = () => {
  const { id } = useParams();

  const [activeButton, setActiveButton] = useState("");

  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(1);

  console.log("refreshKey", refreshKey);
  useEffect(() => {
    const loadClient = async () => {
      if (id) {
        try {
          const response = await api.get(`/clients/${id}`);
          console.log("EDIT INFO NEW", response.data);
          setData(response.data);
        } catch (error) {
          console.error(error.response.data);
        }
      }
    };
    loadClient();
  }, [id, refreshKey]);

  const [activeTab, setActiveTab] = useState(1);
  const items = [
    "Client Details",
    "Policies",
    "Email Sent",
    "Communication",
    // "Notes",
  ]; // Your list of items

  return (
    <Fragment>
      <Card>
        <CardBody style={{ height: "73px" }}>
          <h6>
            <strong>{data.CLI_InsuredFullName}</strong>
          </h6>

          <div
            style={{
              borderTop: "1px solid #dee2e6",
              marginLeft: "-16px",
              width: "103%",
              height: "7px",
            }}
          >
            <div style={{ marginLeft: "16px" }}>
              <tr>
                {items.map((item, index) => (
                  <td
                    onClick={() => {
                      setActiveTab(index + 1);
                    }}
                    style={{
                      width: "110px",
                      cursor: "pointer",
                      padding: "4px",
                      fontSize: "13px",
                      textAlign: "center",
                      borderBottom:
                        activeTab === index + 1
                          ? "2px solid red"
                          : "transparent",
                    }}
                  >
                    <strong className="text-muted">{item}</strong>
                  </td>
                ))}
              </tr>
            </div>
          </div>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Details
                data={data}
                refreshKey={refreshKey}
                setRefreshKey={setRefreshKey}
              />
            </TabPane>

            <TabPane tabId={2}>
              <Policies policies={data.policies} />
            </TabPane>
            <TabPane tabId={3}>
              <EmailSent client_name ={data.CLI_InsuredFullName}  emails={data.emails_sent} policies={data.policies} />
            </TabPane>
            <TabPane tabId={4}>
              <Communications
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                policies={data.policies}
                contacted={data.contacted}
              />
            </TabPane>

            {/* <TabPane tabId={5}>
              <Notes />
            </TabPane> */}
          </TabContent>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default EditClient;
