import React, { useEffect, useState } from "react";
import {
  Button,
  CardText,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../service/api";
import { SweetAlert } from "../../../../components/Alerts";
import { useAuth } from "../../../../context/AuthContext";
import {
  AiOutlinePoweroff,
  AiOutlineEyeInvisible,
  AiFillBell,
  AiOutlineEye,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { getUser } from "../../../../service/auth";
import { formatDate } from "../../../../utility/FormatDate";
import { formatCurrency } from "../../../../utility/FormatCurrency";

function Assign({ refreshKey, setRefreshKey, data }) {
  const [modal, setModal] = useState(false);

  const user = getUser();

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const [loading, setIsLoading] = useState(false);

  const [deadline, setDeadline] = useState("");
  const [agent, setAgent] = useState("");

  console.log("ABD", data);

  const handleSubmit = () => {
    try {
      const response = api.post(`assign`, {
        user_id: agent,
        deadline,
        client_id: data.CLI_ClientNo,
      });

      setRefreshKey(refreshKey + 1);
      SweetAlert({
        title: "Actualizado Com Sucesso!",
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      SweetAlert({
        title: "Ocorreu um erro interno ao tentar efectuar a operação",
        text: "Se o problema persistir contacte o suporte técnico.",
        icon: "warning",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        outline
        style={{ color: "#000", border: "1px solid #000" }}
        onClick={toggle}
        size="sm"
      >
        Assign Agent
      </Button>

      <Modal isOpen={modal} toggle={toggle} style={{ width: "740px" }}>
        <ModalHeader>
          <h5
            className="fw-700"
            style={{ fontSize: "18px", textAlign: "center" }}
          >
            Assign Agent{" "}
          </h5>
        </ModalHeader>

        <ModalBody>
          <Row style={{ marginTop: "6px", height: "57px" }}>
            {" "}
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  Deadline{" "}
                </Label>
                <Input
                  bsSize="sm"
                  type="date"
                  onChange={(e) => {
                    setDeadline(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="memberprovider" style={{ lineHeight: "0px" }}>
                  {" "}
                  Agente{" "}
                </Label>
                <Input
                  bsSize="sm"
                  type="select"
                  onChange={(e) => {
                    setAgent(e.target.value);
                  }}
                >
                  <option></option>
                  <option value={126}>Asher Manhique</option>
                  <option value={125}>Gledsy Cumbe</option>
                  <option value={124}>Vasco Macamo</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            style={{
              backgroundColor: "#fff",
              color: "#747587",
              border: "none",
            }}
          >
            <span className="ms-1 me-1">Close </span>
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
              toggle();
            }}
            color="primary"
            className="btn-sm rounded"
            bssize="sm"
            style={{ width: "134px", marginLeft: "20px" }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Assign;
