import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

import { AiOutlineClose, AiOutlineEye } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../../utility/FormatCurrency";
const Policies = ({ policies }) => {
  const columns = [
    {
      name: "#",
      selector: (row) => <span>{row.P_PolicyNo}</span>,
      sortable: true,
    },

    {
      name: "Produto",
      allowOverflow: true,
      selector: (row) => <span>{row.P_OtherCode}</span>,
    },
    {
      name: "Nº da apólice",
      allowOverflow: true,
      selector: (row) => <span>{row.P_OtherPolicyNo}</span>,
    },
    {
      name: "Data de expiração",

      selector: (row) => {
        const grailDate = new Date("1800-12-28");

        grailDate.setDate(grailDate.getDate() + row.P_RenewalDate);

        const formattedDate = `${grailDate.getFullYear()}-${String(
          grailDate.getMonth() + 1
        ).padStart(2, "0")}-${String(grailDate.getDate()).padStart(2, "0")}`;

        return <span>{formattedDate}</span>;
      },
    },
    {
      name: "Prémio Anual",
      allowOverflow: true,
      selector: (row) => <span>{formatCurrency(Number(row.P_Premium))}</span>,
    },
    {
      name: "Corretor",

      selector: (row) => <span>{row.broker.B_BrokerName} hh</span>,
    },
    {
      name: "Ações",
      allowOverflow: true,
      maxWidth: "140px",
      cell: (row) => {
        return (
          <div className="d-flex flex-row justify-content-end align-items-center ">
            <FormGroup>
              <Link to={`/policy/${row.P_PolicyNo}`}>
                <Button
                  className={`btn-icon btn-sm rounded-circle mx-1`}
                  style={{ backgroundColor: "#fff", border: "1px solid #fff" }}
                >
                  <AiOutlineEye size={18} color="black" />
                </Button>
              </Link>
            </FormGroup>
          </div>
        );
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px !important", // override the row height
        fontSize: "12px",
        whiteSpace: "pre",
        paddingTop: "0px",
        paddingLeft: "8px",
        paddingBottom: "0px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "20px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12px",
        marginTop: "20px",
        minHeight: "0px !important",
        color: "#818486",
        fontWeight: "500",
      },
    },
  };

  return (
    <Fragment>
      <div style={{}}>
        <h6 style={{ padding: "5px", lineHeight: "0px" }}>
          <strong>Policies</strong>
        </h6>
      </div>

      <hr />
      <Row>
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={policies}
        />
      </Row>
    </Fragment>
  );
};

export default Policies;
