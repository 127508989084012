import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { BsSearch } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { TbCalendarDue } from "react-icons/tb";
import ModalEdit from "./ModalEdit";
import { api } from "../../service/api";
import DataTable from "react-data-table-component";

import { FaChevronDown } from "react-icons/fa";
import ReactPaginate from "react-paginate";

import { Link } from "react-router-dom";
import SendNotif from "./Edit/Modals/SendNotif";
const Client = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [client_name, setClientName] = useState([]);
  const [policy_no, setPolicyNo] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchPage, setFetchPage] = useState(true);

  useEffect(() => {
    setFetchPage(true);
    loadClients();
  }, [currentPage]);

  const search = () => {
    loadClients({ client_name, policy_no });
  };

  const loadClients = async () => {
    let page = fetchPage ? Number(currentPage) + 1 : 1;
    setLoading(true);
    try {
      const response = await api.get("/clients", {
        params: {
          // paginate: true,
          // page,
          client_name,
          policy_no,
        },
      });
      setData(response.data);

      setTotalRows(response.data.total);
    } catch (error) {
    } finally {
      setLoading(false);
      setFetchPage(false);
    }
  };

  const columns = [
    {
      name: "Nome do Cliente",
      maxWidth: "240px",

      selector: (row) => <span>{row.CLI_InsuredFullName}</span>,
    },
    {
      name: "Email",
      maxWidth: "200px",
      selector: (row) => <span>{row.CLI_email}</span>,
    },
    {
      name: "Detalhes do contacto",
      allowOverflow: true,
      selector: (row) => (
        <div>
          <span>{row.CLI_CellNumber}</span>
        </div>
      ),
    },
    {
      name: "Numero de Apolice",

      allowOverflow: true,
      selector: (row) => {
        return <span>{row?.policies[0]?.P_OtherPolicyNo}</span>;
      },
    },
    {
      name: "Tipo de apolice",

      selector: (row) => <span>{row?.policies[0]?.P_OtherCode}</span>,
    },
    {
      name: "Estado do Cliente",
      allowOverflow: true,

      cell: (row) => {
        return (
          <div className="d-flex flex-row justify-content-end align-items-center ">
            {row?.policies[0]?.P_Status == "A" ? "Activo" : "Cancelado"}
          </div>
        );
      },
    },

    {
      name: "Ações",
      allowOverflow: true,

      cell: (row) => {
        return (
          <div className="d-flex flex-row justify-content-end align-items-center ">
            <FormGroup>
              <Link to={`/clients/${row.CLI_ClientNo}`}>
                <Button
                  className={`btn-icon btn-sm rounded-circle mx-1`}
                  style={{ backgroundColor: "#fff", border: "1px solid #fff" }}
                >
                  <AiOutlineEye size={18} color="black" />
                </Button>
              </Link>
            </FormGroup>
          </div>
        );
      },
    },
  ];

  //THIS STYLES THE DATA TABLE

  return (
    <Fragment>
      <h4 className="mb-4">Clients</h4>
      <div className="mb-3 d-flex gap-3">
        <div>
          <label htmlFor="search" className="form-label">
            Search
          </label>
          <div className="input-group">
            <span className="input-group-text bg-white" id="search">
              <BsSearch />
            </span>
            <input
              onChange={(e) => {
                setClientName(e.target.value);
              }}
              type="text"
              className="form-control form-control-sm"
              placeholder="nome do cliente"
            />
          </div>
        </div>
        <div>
          <label htmlFor="start_date" className="form-label">
            Policy
          </label>
          <Input
            className="form-control form-control-sm"
            type="text"
            placeholder="numero da apolice"
            onChange={(e) => {
              setPolicyNo(e.target.value);
            }}
          />
        </div>
        {/* <div>
          <label htmlFor="end_date" className="form-label">
            Policy Status
          </label>
          <select
            className="form-select form-select-sm"
            aria-label="Default select example"
          >
            <option selected>All</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div> */}
        <div>
          <label htmlFor="end_date" className="form-label text-white">
            .
          </label>
          <div className="input-group">
            <Button
              onClick={() => {
                search();
              }}
              color="danger"
              outline
              size="sm"
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <DataTable
        style={{ width: "100%" }}
        columns={columns}
        noHeader
        data={data}
        pagination
        paginationPerPage={10}
        progressPending={loading}
      />
    </Fragment>
  );
};

export default Client;
