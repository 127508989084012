export const formatDate = (days) => {
  const grailDate = new Date("1800-12-28");

  grailDate.setDate(grailDate.getDate() + days);

  const formattedDate = `${grailDate.getFullYear()}-${String(
    grailDate.getMonth() + 1
  ).padStart(2, "0")}-${String(grailDate.getDate()).padStart(2, "0")}`;
  return formattedDate;
};
