import React from "react";
import Chart from "react-apexcharts";

const DonuChart = ({labels, series}) => {
    const options = {
        labels: labels,
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: "80px",
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontSize: "24px",
                        }
                    }
                }
            }
        },
        
    }
    return (
        <Chart options={options} series={series} type="donut" width="350px" height="350px" />
    );
}

export default DonuChart;