import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./index.css";
import { getUser } from "../../service/auth";

const SideBar = () => {

  const user = getUser();


  const activeSideBar = () => {
    const currentPage = window.location.href;
    const currentItem = document.querySelectorAll(".link");
    for (let i = 0; i < currentItem.length; i++) {
      if (currentItem[i].href.startsWith(currentPage)) {
        currentItem[i].classList.add("active");
        break;
      }
    }
  };
  activeSideBar();
  const menuItems = [
    {
      path: "/dashboard",
      name: "Home",
    },
    {
      path: "/categories",
      name: "Comunicações",
    },
    {
      path: "/clients",
      name: "Clientes",
    },
    // {
    //   path: "/renovations",
    //   name: "Renovações do mês",
    // },

    {
      path: "/reports",
      name: "Reports",
    },
  ];

  const currentDate = new Date();

  const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')}/${currentDate.getFullYear()}`;

  return (
    <aside id="sidebar">
      <header id="sidebar_header" className="border-bottom px-4 py-2">
        <span id="username"> {user.name}</span>
        <p id="totalEmails" className="mb-0 text-nowrap">
          Data: <span>{formattedDate}</span>
        </p>
      </header>
      <nav id="sidebar_nav" className="d-flex flex-column py-2">
        {menuItems.map((item, index) => (
          <NavLink to={item.path} key={index} className="link px-4 py-2">
            {item.name}
          </NavLink>
        ))}
      </nav>
    </aside>
  );
};

export default SideBar;
