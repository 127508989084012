import React, { Fragment, useEffect, useState } from "react";
import "./index.css";
import { FaDollarSign } from "react-icons/fa";
import { TbCalendarDue } from "react-icons/tb";
import { FaArrowsRotate } from "react-icons/fa6";
import { BsBell } from "react-icons/bs";
import { Table } from "reactstrap";
import DonuChart from "../../components/DonutChart";
import { api } from "../../service/api";

const Home = () => {
  const recents = [
    {
      name: "Tomás Solivan",
      email: "tomass89@gmail.com",
      cotegory: "Notification",
      policy: "000000000",
      status: "Undelivered",
      date: "2023-09-23",
    },
    {
      name: "Christina Gerola Malonque",
      email: "tomass89@gmail.com",
      cotegory: "Renewals",
      policy: "000000000",
      status: "Successful",
      date: "2023-09-24",
    },
    {
      name: "Tomás Solivan",
      email: "tomass89@gmail.com",
      cotegory: "Notification",
      policy: "000000000",
      status: "Unsent",
      date: "2023-09-23",
    },
    {
      name: "Christina Gerola Malonque",
      email: "tomass89@gmail.com",
      cotegory: "Renewals",
      policy: "000000000",
      status: "Successful",
      date: "2023-09-24",
    },
    {
      name: "Tomás Solivan",
      email: "tomass89@gmail.com",
      cotegory: "Billing",
      policy: "000000000",
      status: "Undelivered",
      date: "2023-09-23",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    loadSent();
  }, []);

  const loadSent = async () => {
    try {
      const response = await api.get("/sent", {});
      setData(response.data);
      console.log("RESPONSE DTAA", response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const last6Records = data.slice(0, 6);

  const renovation = "RENOVATION";
  const birthday = "BIRTHDAY";
  const payment = "PAYMENT";

  const billing = "BILLING";

  const receipt = "RECEIPT";

  // Use the reduce function to count records with the specified type
  const countOfRenovationRecords = data?.reduce((count, record) => {
    if (record.type_enum === renovation) {
      return count + 1;
    }
    return count;
  }, 0);

  const countOfBillingRecords = data?.reduce((count, record) => {
    if (record.type_enum === billing) {
      return count + 1;
    }
    return count;
  }, 0);

  const countOfBirthdayRecords = data?.reduce((count, record) => {
    if (record.type_enum === birthday) {
      return count + 1;
    }
    return count;
  }, 0);

  const countOfEmail = data?.reduce((count, record) => {
    if (record.notification_type === "Email") {
      return count + 1;
    }
    return count;
  }, 0);

  const countOfSMS = data?.reduce((count, record) => {
    if (record.notification_type === "SMS") {
      return count + 1;
    }
    return count;
  }, 0);
  return (
    <Fragment>
      <h4 className="mb-4">Dashboard</h4>
      <div className="grid-container mb-4">
        <div className="grid-left">
          <div className="border rounded p-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <span className="text-muted"> Cobranças</span>
              <FaDollarSign size={18} />
            </div>
            <span
              className="text-dark"
              style={{ fontWeight: 500, fontSize: 22 }}
            >
              {countOfBillingRecords}
            </span>
          </div>
          <div className="border rounded p-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <span className="text-muted">Aniversários</span>
              <TbCalendarDue size={18} />
            </div>
            <span
              className="text-dark"
              style={{ fontWeight: 500, fontSize: 22 }}
            >
              {countOfBirthdayRecords}
            </span>
          </div>
          <div className="border rounded p-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <span className="text-muted">Renovações</span>
              <FaArrowsRotate size={18} />
            </div>
            <span
              className="text-dark"
              style={{ fontWeight: 500, fontSize: 22 }}
            >
              {countOfRenovationRecords}
            </span>
          </div>
          <div className="border rounded p-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <span className="text-muted">Comunicações</span>
              <BsBell size={18} />
            </div>
            <span
              className="text-dark"
              style={{ fontWeight: 500, fontSize: 22 }}
            >
              {data.length}
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center ms-3 border rounded p-3">
          <div>
            <h5>Notificações enviadas</h5>
            <div className="donut">
              <DonuChart
                labels={["Emails", "SMS"]}
                series={[countOfEmail, countOfSMS]}
              />
              {/* <p>*Not accurate as of yet</p> */}
            </div>
          </div>
        </div>
      </div>
      <h5 className="mb-3">Recent Emails</h5>
      <Table className="border">
        <thead>
          <tr className="table-secondary text-muted">
            <th>Nome</th>
            <th>Contacto</th>
            <th>Método</th>
            <th>Apólice</th>

            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {last6Records.map((item, index) => (
            <tr key={index}>
              <td>{item.policy?.P_Insured} </td>
              <td>{item.email !== null ? item.email : item.cell_no}</td>
              <td>{item.notification_type}</td>
              <td>{item.policy_no}</td>

              <td>{item.date}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default Home;
