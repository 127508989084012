import React, { Fragment, useState } from "react";
import {
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { api, baseURL } from "../../service/api";
import { useAuth } from "../../context/AuthContext";
// import { SYSTEM_ROUTES } from "../../navigation";
import "./style.css";
// import { login, saveUser } from "../../service/auth";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const { setAuthenticated, setUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
   

    axios
      .get(baseURL + "/sanctum/csrf-cookie")
      .then(async (response) => {
        try {
          const response = await api.post(`/auth/login`, {
            email,
            password,
          });
          setUser(response.data.data.user);
          // login(response.data.data.access_token);
          // saveUser(response.data.data.user);
          setAuthenticated(true);
          navigate('/home');
        } catch (error) {
          if (error.response) {
            if (error.response.status === 422) {
              setErrors(error.response.data.errors);
            }

            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage("We are having connection problems!");
          }
        } finally {
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Fragment>
      <div className="app" style={{ border: "1px" }}>
        <div className="card-content">
          <div className="card-auth mt-3">
            <img
              style={{ textAlign: "center", marginLeft: "130px" }}
              src="https://palmaseguros.tech/PalmtreesLo.png"
              height="32px"
              width="100px"
            />
            <h4 className="text-center trav">Email Management</h4>

            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup style={{ marginTop: "28px" }}>
                  <Label htmlFor="email">E-mail</Label>
                  <Input
                    id="email"
                    invalid={errorMessage}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoFocus
                  />
                  {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
                </FormGroup>

                <FormGroup style={{ marginTop: "25px" }}>
                  <Label for="password">Senha</Label>
                  <Input
                    id="password"
                    type="password"
                    invalid={errors.password}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </FormGroup>

                <Button
                  block
                  className="text-uppercase"
                  disabled={isLoading}
                  style={{
                    background: "#D12421",
                    outline: "none",
                    border: "none",
                    marginTop: "32px",
                  }}
                >
                  {isLoading ? (
                    <Fragment>
                      <Spinner size={"sm"} />
                      <i>
                        <small>Processando...</small>
                      </i>
                    </Fragment>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form>
            </CardBody>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
